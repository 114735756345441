import EventBus from '@ui/eventBus.js';
import platform from 'platform';
import StackTracey from 'stacktracey';
import StackTrace from 'stacktrace-js';

function platformInfo() {
    return platform;
}

function screenInfo() {
    return {
        screenWidth: screen.width,
        screenHeight: screen.height,
        availableWidth: screen.availWidth,
        availableHeight: screen.availHeight,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
    };
}

function sendLog(data) {
    window.axios.post('/api/v1/exception-report', {log: data}).then((response) => {
        if (import.meta.env.VITE_PUSHER_ENV === 'local') {
            EventBus.$emit('debug', {
                'frontend': {
                    route: response.data.route,
                    data: data,
                }
            });
        } else {
            EventBus.$emit('flash-error', 'Er ging iets fout, we zijn hiervan op de hoogte gebracht');
        }
    }).catch((error) => {
        console.log('whoeps', error);
    });
}

/**
 * Handle
 */
export function logError(error = null, data = null) {
    if (!data) {
        let data = {};
    }
    // add user
    data.user = {};

    if (window.user) {
        data.user = {
            'name': window.user.name,
            'language': window.user.language.code.toLowerCase(),
            'disguised': window.user.disguised,
            'preferences': window.user.preferences,
        };
    }

    // add screen info
    data.screen = screenInfo();
    // add cookies
    data.cookies = document.cookie;
    // add url
    data.url = document.location.href;
    // add platform data
    data.platform = platformInfo();
    // add stack if available
    if (error) {
        if (import.meta.env.VITE_PUSHER_ENV === 'local') {
            StackTrace.fromError(error).then((stack) => {
                data.stackTrace = stack;
                //sendLog(data);
            }).catch((stackError) => {
                data.stackError = stackError;
                //sendLog(data);
            });
        } else {
            new StackTracey(error).withSourceAsyncAt(0).then((stack) => {
                data.stackTrace = stack;
                sendLog(data);
            }).catch((stackError) => {
                data.stackError = stackError;
                sendLog(data);
            });
        }
    }
}
