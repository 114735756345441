import EventBus from "@ui/eventBus";

export default {
    methods: {
        focusNextField(event = null, currentIndex = null) {
            const allowedFields = ['INPUT', 'TEXTAREA', 'BUTTON', 'LABEL'];
            let focussable = this.getFoccussable();
            let focussableLength = focussable.length;
            let focusFirst = true;
            //get tabindex for select element
            if (currentIndex === null) {
                if (!document.activeElement.hasAttribute('tabindex') && document.activeElement.closest('.select')) {
                    currentIndex = parseInt(document.activeElement.closest('.select').getAttribute('tabindex'));
                } else {
                    currentIndex = document.activeElement.tabIndex;
                }
            }
            let hasClass = false;
            if (document.activeElement.classList.contains('multiselect') || document.activeElement.classList.contains('select')) {
                hasClass = true;
            }
            if ((allowedFields.includes(document.activeElement.tagName) || hasClass) && currentIndex > -1 && focussableLength) {
                if (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                if (currentIndex > -1) {
                    for (var i= 0; i < focussableLength; i++) {
                        //if next tab focus
                        if (focussable[i].tabIndex >= (currentIndex+1)) {
                            focussable[i].focus();
                            focusFirst = false;
                            break;
                        }
                        if (this.focusLastElementEvent && focussableLength - 1 === i) {
                            EventBus.$emit('focusedLastElementEvent');
                        }
                    }
                }
            }
            if (focusFirst && focussableLength) {
                focussable[0].focus();
            }
        },
        focusFieldIndex(index) {
            let focussable = this.getFoccussable();
            if (typeof focussable[index] !== 'undefined') {
                focussable[index].focus();
            }
        },
        getFoccussable() {
            const activeModal = document.querySelector('.active-modal');
            if (!activeModal) {
                return [];
            }
            const modalId = activeModal.id;
            const focussable = Array.prototype.filter.call(
                document.querySelectorAll(`#${modalId} [tabindex]:not([disabled]):not([readonly]):not([tabindex="-1"])`), function (element) {
                    let disabled = false;
                    let readonly = false;
                    if (element.classList.contains('select')) {
                        disabled = element.querySelector('.dropdown-button .selected').disabled;
                        readonly = element.querySelector('.dropdown-button .selected[readonly]') != null ? true : false;
                    }
                    if (element.classList.contains('disabled')) {
                        disabled = true;
                    }
                    //check for visibility while always include the current activeElement
                    return (element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement) && !disabled && !readonly;
                });
            //sort array by tabindex
            return focussable.sort(function(a, b) {
                return a.tabIndex - b.tabIndex;
            });
        }
    }
};
