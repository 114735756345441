<template>
  <beekman-button v-if="button.type == 'event'"
                  ref="event"
                  :id="button.identifier"
                  :key="button.text"
                  :disabled="(checkDisabled() || tabHasLock) && !button.ignoreDisabled"
                  :styling="styling"
                  @click="handleButton(button)">
    <template v-if="onlyIcon">
      <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
    </template>
    <template v-else>
      <img v-if="button.image" :src="button.image" class="mr-2"/>
      <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
      {{ button.text }}
    </template>
    <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
  </beekman-button>
  <beekman-export-button
      v-if="button.type == 'export'"
      ref="export"
      :id="button.identifier"
      :class="button.hoverClasses"
      :disabled="(checkDisabled() || tabHasLock) && !button.ignoreDisabled"
      :route="button.route"
      :route-params="button.routeParams"
      :styling="styling"
      @loading="loading"
      @click="handleButton(button)"
      @exportSuccess="refresh"
      @exportError="refresh"
  >
    <template v-if="button.tooltip.content">
      <beekman-tooltip :class="button.tooltip.classes" :content="button.tooltip.content">
        <template v-if="onlyIcon">
          <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
        </template>
        <template v-else>
          <img v-if="button.image" :src="button.image" class="mr-2"/>
          <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
          {{ button.text }}
        </template>
        <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
      </beekman-tooltip>
    </template>
    <template v-else>
      <template v-if="onlyIcon">
        <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
      </template>
      <template v-else>
        <img v-if="button.image" :src="button.image" class="mr-2"/>
        <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
        {{ button.text }}
      </template>
      <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
    </template>
  </beekman-export-button>
  <beekman-confirm-button
      v-if="button.type == 'confirm'"
      ref="confirm"
      :id="button.identifier"
      :class="button.hoverClasses"
      :confirm="button.confirm"
      :confirm-text="button.confirmText"
      :disabled="(checkDisabled() || tabHasLock) && !button.ignoreDisabled"
      :event-after-success="button.eventAfterSuccess"
      :fields="button.fields"
      :route-confirm-data="button.routeConfirmData"
      :auto-focus-confirm="button.autoFocusConfirm"
      :on-confirm="button.route"
      :on-confirm-route-params="button.routeParams"
      :content="button.content"
      :config="button.config"
      :styling="styling"
      @loading="loading"
      @confirming="confirming"
      @click="handleButton(button)"
      @confirmSuccess="refresh"
      @confirmError="refresh">
    <template v-if="button.tooltip.content">
      <beekman-tooltip :class="button.tooltip.classes" :content="button.tooltip.content">
        <template v-if="onlyIcon">
          <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
        </template>
        <template v-else>
          <img v-if="button.image" :src="button.image" class="mr-2"/>
          <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
          {{ button.text }}
        </template>
        <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
      </beekman-tooltip>
    </template>
    <template v-else>
      <template v-if="onlyIcon">
        <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
      </template>
      <template v-else>
        <img v-if="button.image" :src="button.image" class="mr-2"/>
        <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
        {{ button.text }}
      </template>
      <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
    </template>
  </beekman-confirm-button>
  <beekman-dynamic-modal-button v-if="button.type == 'dynamicModal'"
                                ref="dynamicModal"
                                :key="button.text"
                                :title="button.text"
                                :disabled="checkDisabled() && !button.ignoreDisabled"
                                :styling="styling"
                                :route-url="button.route"
                                :route-params="button.routeParams"
                                :tab-has-lock="tabHasLock"
                                @loading="loading"
                                @click="handleButton(button)">
    <template v-if="onlyIcon">
      <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
    </template>
    <template v-else>
      <img v-if="button.image" :src="button.image" class="mr-2"/>
      <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
      {{ button.text }}
    </template>
    <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
  </beekman-dynamic-modal-button>
  <beekman-edit-modal-button v-if="button.type == 'editModal'"
                             ref="editModal"
                             :id="button.identifier"
                             :key="button.text"
                             :disabled="(checkDisabled() || tabHasLock) && !button.ignoreDisabled"
                             :styling="styling"
                             :url="button.url != '' ? button.url : $route(button.urlFromData.route, setRouteParams(button.urlFromData.params, data))"
                             :title="button.text"
                             @loading="loading"
                             @click="handleButton(button)">
    <template v-if="onlyIcon">
      <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
    </template>
    <template v-else-if="asLink">
      {{ button.text }}
    </template>
    <template v-else>
      <img v-if="button.image" :src="button.image" class="mr-2"/>
      <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
      {{ button.text }}
    </template>
    <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
  </beekman-edit-modal-button>
  <beekman-create-modal-button v-if="button.type == 'createModal'"
                               ref="createModal"
                               :id="button.identifier"
                               :key="button.text"
                               :disabled="(checkDisabled() || tabHasLock) && !button.ignoreDisabled"
                               :styling="styling"
                               :url="button.url"
                               @submit="$emit('submitted')"
                               @close="$emit('close')"
                               :selectedApplicationId="selectedApplicationId"
                               @selectApplications="this.$emit('selectApplications', $event)"
                               @click="handleButton(button)">

    <template v-if="button.tooltip.content">
      <beekman-tooltip :class="button.tooltip.classes" :content="button.tooltip.content">
        <template v-if="onlyIcon">
          <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
        </template>
        <template v-else>
          <img v-if="button.image" :src="button.image" class="mr-2"/>
          <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
          {{ button.text }}
        </template>
        <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
      </beekman-tooltip>
    </template>
    <template v-else>
      <template v-if="onlyIcon">
        <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
      </template>
      <template v-else>
        <img v-if="button.image" :src="button.image" class="mr-2"/>
        <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
        {{ button.text }}
      </template>
      <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
    </template>
  </beekman-create-modal-button>
  <beekman-link v-if="button.type == 'link' && button.linkFromData ? data[button.linkFromData] : button.link"
                ref="link"
                :id="button.identifier"
                :key="button.text"
                :disabled="checkDisabled() && !button.ignoreDisabled"
                :link="button.linkFromData ? data[button.linkFromData] : button.link"
                :new-window="button.newWindow"
                :styling="styling"
                @click="handleButton(button)">
    <template v-if="onlyIcon">
      <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
    </template>
    <template v-else>
      <img v-if="button.image" :src="button.image" class="mr-2"/>
      <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
      {{ button.text }}
    </template>
    <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
  </beekman-link>
  <beekman-button v-if="button.type == 'download'"
                  ref="download"
                  :styling="styling"
                  :disabled="(checkDisabled() || tabHasLock) && !button.ignoreDisabled"
                  @click="downloadFile(button)">
    <template v-if="onlyIcon">
      <i v-if="button.icon" :class="[button.icon]" class="w-5" :title="button.text"></i>
    </template>
    <template v-else>
      <img v-if="button.image" :src="button.image" class="mr-2"/>
      <i v-if="button.icon" :class="[button.icon]" class="mr-2"></i>
      {{ button.text }}
    </template>
  </beekman-button>
  <span v-if="button.type == 'toDo'" ref="toDo" :class="styling" class="button bg-red-500 text-white" :disabled="(checkDisabled() || tabHasLock) && !button.ignoreDisabled">
    <template v-if="onlyIcon">
      <i v-if="button.icon" class="fal fa-exclamation-triangle w-5" :title="button.text"></i>
    </template>
    <template v-else>
      <i class="fal fa-exclamation-triangle mr-2"></i>
      {{ button.text }}
    </template>
    <beekman-badge-count v-if="button.badge && button.badge.count > 0" :badge-count="button.badge.count" :classes="button.badge.classes"/>
  </span>
</template>

<script>
import BeekmanButton from '@ui/Components/Button.vue';
import BeekmanConfirmButton from "@ui/Components/ConfirmButton.vue";
import BeekmanTooltip from "@ui/Components/Tooltip.vue";
import EventBus from '@ui/eventBus.js';
import {defineAsyncComponent} from "vue";
import SetRouteParams from "@crud/Mixins/Table/SetRouteParams.js";

export default {
  components: {
    BeekmanButton,
    BeekmanConfirmButton,
    EventBus,
    BeekmanTooltip,
    'BeekmanDynamicModalButton': defineAsyncComponent(() => import(/* webpackChunkName: "buttons" */ '@ui/Components/Buttons/DynamicModalButton.vue')),
    'BeekmanEditModalButton': defineAsyncComponent(() => import(/* webpackChunkName: "buttons" */ '@ui/Components/Buttons/EditModalButton.vue')),
    'BeekmanCreateModalButton': defineAsyncComponent(() => import(/* webpackChunkName: "buttons" */ '@ui/Components/Buttons/CreateModalButton.vue')),
    'BeekmanExportButton': defineAsyncComponent(() => import(/* webpackChunkName: "buttons" */ '@ui/Components/Buttons/ExportButton.vue')),
    'BeekmanBadgeCount': defineAsyncComponent(() => import(/* webpackChunkName: "buttons" */ '@ui/Components/Buttons/BadgeCount.vue')),
    'BeekmanLink': defineAsyncComponent(() => import(/* webpackChunkName: "buttons" */ '@ui/Components/Buttons/Link.vue')),
  },
  mixins: [
    SetRouteParams,
  ],
  emits: ['refresh', 'clicked', 'loading', 'submitted', 'confirming', 'selectApplications', 'close'],
  props: {
    button: {
      type: Object,
      default() {
        return {};
      },
    },
    styling: {
      default: 'secondary'
    },
    buttonsDisabled: {
      default: true
    },
    hasLock: {
      default: false
    },
    tabHasLock: {
      default: false
    },
    onlyIcon: {
      default: false
    },
    asLink: {
      default: false
    },
    selectedApplicationId: {
      default: null
    },
    data: {
      type: [Array, Object],
      default: [],
    }
  },
  data() {
    return {
      disabledWhileLoading: false,
    };
  },
  methods: {
    buttonTooltip(button) {
      if (button.tooltip) {
        let content = '';
        let classes = '';
        if (button.tooltip.classes) {
          classes = button.tooltip.classes;
        }
        if (button.tooltip.content) {
          content = this.trans(button.tooltip.content);
        }
        return {content, classes}
      }
      return {};
    },
    refresh(payload) {
      this.$emit('refresh');
    },
    loading(loading) {
      this.disabledWhileLoading = loading;
      this.$emit('loading', loading);
    },
    confirming(confirming) {
      this.$emit('confirming', confirming);
    },
    handleButton(button) {
      if (button.event) {
        EventBus.$emit(button.event, button.properties);
      } else {
        this.$emit('clicked');
      }
    },
    handleKeydown(e) {
      if (this.button.shortkey && e.ctrlKey === true && e.key === this.button.shortkey) {
        this.$refs[this.button.type].$el.click();
      }
    },
    downloadFile(button) {
      window.location = this.$route('export.download', {id: button.downloadId});
      axios.post(this.$route('export.downloaded', {id: button.downloadId}));
    },
    checkDisabled() {
      return this.buttonsDisabled || this.button.disabled || (this.hasLock && this.button.disabledByLock);
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  }
}
</script>
<script setup lang="ts">
</script>