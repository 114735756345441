import * as debugHelpers from "@/beekman/debug";
import moment from "moment/moment";
import {Link as InertiaLink} from "@inertiajs/vue3";
import Translations from "@ui/Mixins/Translations";
import PortalVue from "portal-vue";
import drag from "@ui/Drag";
import {createPinia} from "pinia";
import piniaPersist from "pinia-plugin-persist";
import VueLazyLoad from "vue3-lazyload";
import EventBus from "@ui/Plugins/BeekmanEvents";
import BeekmanTranslations from "@ui/Plugins/BeekmanTranslations";
import BeekmanUser from "@ui/Plugins/BeekmanUser";
import BeekmanCustomHeaderData from "@ui/Plugins/BeekmanCustomHeaderData";
import BeekmanBarcodeScanner from "@ui/Plugins/BeekmanBarcodeScanner";
import BeekmanRoutes from "@ui/Plugins/BeekmanRoutes";
import FloatingVue from "floating-vue";
import AppLayout from "@ui/Layouts/AppLayout.vue";

export function vueInit(app) {
    if (import.meta.env.VITE_PUSHER_ENV === 'local') {
        app.config.performance = true;
    } else {
        app.config.performance = false;
    }

    app.config.errorHandler = (error, instance, info) => {
        const data = {
            type: 'vue',
            //instance: instance,
            info: info,
            message: error.message,
        };

        debugHelpers.logError(error, data);
    }
    app.config.globalProperties.$customPasteImageUpload = false;
    app.config.globalProperties.$setCustomPasteImageUpload = function (boolean) {
        app.config.globalProperties.$customPasteImageUpload = boolean;
    }

    app.config.globalProperties.$filters = {
        formatDate(value, format) {
            let useFormat = 'DD-MM-YYYY HH:mm:ss'
            if (!value) {
                return '';
            }
            if (format) {
                useFormat = format;
            }
            return moment(String(value), ["DD-MM-YYYY HH:mm:ss", "YYYY-MM-DD HH:mm:ss", "DD-MM-YYYY", "YYYY-MM-DD"]).format(useFormat);
        },
        timeago(value, locale = 'nl-NL') {
            return moment(String(value), ["DD-MM-YYYY HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]).locale(locale).fromNow();
        },
        formatCurrency(value, currency = 'EUR', locale = 'nl-NL') {
            return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value);
        },
        formatNumber(value, locale = 'nl-NL', digits = 0) {
            return new Intl.NumberFormat(locale, {minimumFractionDigits: digits}).format(value);
        },
        formatWeight(value, locale = 'nl-NL', unit = 'kilogram') {
            return new Intl.NumberFormat(locale, {style: 'unit', unit: unit}).format(value);
        },
        formatDuration(value, locale = 'nl-NL', unit = 'second') {
            return new Intl.NumberFormat(locale, {style: 'unit', unit: unit}).format(value);
        }
    }

    const pinia = createPinia();
    pinia.use(piniaPersist);
    app.use(pinia);

    app.component('InertiaLink', InertiaLink);
    app.mixin(Translations);
    app.mixin({methods: {route}});
    app.use(PortalVue);
    app.use(drag);
    app.use(VueLazyLoad);
    app.use(EventBus);
    app.use(BeekmanTranslations);
    app.use(BeekmanUser);
    app.use(BeekmanCustomHeaderData);
    app.use(BeekmanBarcodeScanner);
    app.use(BeekmanRoutes, {
        isLocal: import.meta.env.VITE_PUSHER_ENV === 'local',
        salt: import.meta.env.VITE_REVERB_APP_KEY ?? import.meta.env.VITE_PUSHER_APP_KEY
    });
    app.use(FloatingVue, {
        themes: {
            'info': {
                $extend: 'tooltip',
                $resetCss: true,
            },
            'error': {
                $extend: 'tooltip',
                $resetCss: true,
            },
            'warning': {
                $extend: 'tooltip',
                $resetCss: true,
            },
            'success': {
                $extend: 'tooltip',
                $resetCss: true,
            },
        },
    });
    return app;
}

export function asyncBeekmanResources() {
    return import.meta.glob('@modules/*/Resources/js/**/*.vue');
}

export function asyncGisResources() {
    return import.meta.glob('@/Pages/**/*.vue');
}

export function customBeekmanResources(module) {
    if (module === 'firewall') {
        return import.meta.glob(`@custom-modules/firewall/Resources/js/**/*.vue`);
    }
}

export function modulePath()
{
    return '/vendor/beekman/core/Modules';
}

export function customModulePath()
{
    return '/vendor/beekman';
}

export function customModules()
{
    return  ["firewall"];
}

export function appPath()
{
    return '/resources/js';
}

export function resolvePages(name)
{
    if (name) {
        const gisPages = asyncGisResources();
        const beekmanPages = asyncBeekmanResources();

        let splitted = name.split('::');

        if (splitted.length == 1) {
            if (name.startsWith('Profile') || name.startsWith('API')) {
                const authPath = `${modulePath()}/Auth/Resources/js/Pages/${name}.vue`;
                const importBeekmanPage = beekmanPages[authPath];
                if (importBeekmanPage) {
                    return importBeekmanPage().then(({default: page}) => {
                        if (page.layout === undefined) {
                            page.layout = AppLayout;
                        }
                        return page;
                    });
                }
                console.log(`No component found for: ${authPath} in ${beekmanPages} for auth`);
            }

            const importPagePath = `${appPath()}/Pages/${name}.vue`;
            const importPage = gisPages[importPagePath];
            if (importPage) {
                return importPage().then(({default: page}) => {
                    if (page.layout === undefined) {
                        page.layout = AppLayout;
                    }
                    return page;
                });
            }
            console.log(`No component found for: ${importPagePath} in ${gisPages} for pages`);
        }

        let [module, page] = splitted;
        const skipLayoutForPages = [
            'Auth/Login',
            'Auth/TwoFactorChallenge',
            'Auth/ForgotPassword',
            'Auth/ResetPassword',
        ];

        if (module) {
            const importBeekmanPagePath = `${modulePath()}/${module}/Resources/js/Pages/${page}.vue`;
            const importBeekmanPage = beekmanPages[importBeekmanPagePath];
            if (importBeekmanPage) {
                return importBeekmanPage().then(({default: activePage}) => {
                    if (activePage.layout === undefined && !skipLayoutForPages.includes(page)) {
                        activePage.layout = AppLayout;
                    }
                    return activePage;
                });
            }

            // custom modules pages
            const type = module.toLowerCase();
            if (customModules().includes(type)) {
                const customBeekmanPages = customBeekmanResources(type);
                const importCustomBeekmanPagePath = `${customModulePath()}/${type}/Resources/js/Pages/${page}.vue`;
                const importCustomBeekmanPage = customBeekmanPages[importCustomBeekmanPagePath];
                if (importCustomBeekmanPage) {
                    return importCustomBeekmanPage().then(({default: activePage}) => {
                        if (activePage.layout === undefined && !skipLayoutForPages.includes(page)) {
                            activePage.layout = AppLayout;
                        }
                        return activePage;
                    });
                }
            }

            console.log(`No component found for: ${importBeekmanPagePath} in ${beekmanPages} for modules`);
        }
    } else {
        console.log('name', name, name);
    }
}
