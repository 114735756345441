import Echo from "laravel-echo";
import {reactive} from "vue";
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const OPTIONS = import.meta.env.VITE_REVERB_ENV === 'local'
    ? {
        broadcaster: 'pusher',
        activityTimeout: 30000,
        pongTimeout: 1500,
        unavailableTimeout: 1500,
        key: import.meta.env.VITE_REVERB_APP_KEY,
        wsHost: import.meta.env.VITE_REVERB_HOST, //window.location.hostname,
        wsPort: 6001,
        wssPort: 6001,
        encrypted: true,
        forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
        enabledTransports: ['ws', 'wss'],
    } : {
        broadcaster: 'pusher',
        activityTimeout: 30000,
        pongTimeout: 1500,
        unavailableTimeout: 1500,
        key: import.meta.env.VITE_REVERB_APP_KEY,
        wsHost: import.meta.env.VITE_REVERB_HOST, //window.location.hostname,
        wsPort: 6001,
        wssPort: 6001,
        encrypted: true,
        forceTLS: true,
        enabledTransports: ['ws', 'wss'],
    };

// make initial connection
window.Echo = new Echo(OPTIONS);
monitorWebsockets();

window.sockets = reactive({
    connected: false,
    status: 'initialized',
    restarted: false,
    error: '',
});

// reconnect if no connection
function reconnectIfNoConnection() {
    if (!window.Echo.connector.pusher.connection.connection) {
        window.sockets.restarted = false;
        window.Echo = null;
        window.Echo = new Echo(OPTIONS);

        setTimeout(() => {
            window.sockets.restarted = true;
            window.sockets.connected = true;
            window.sockets.status = 'connected';
            monitorWebsockets();
            if (reconnectInterval) {
                console.log('Clear interval');
                clearInterval(reconnectInterval);
            }
        }, 1000);
    }
}

// reconnect interval and method
let reconnectInterval = null;

function reconnect() {
    reconnectInterval = setInterval(() => {
        reconnectIfNoConnection();
    }, 1000);
}

function monitorWebsockets() {
    window.Echo.connector.pusher.connection.bind('initialized', () => {
        window.sockets.connected = false;
        window.sockets.status = 'initialized';
    });
    window.Echo.connector.pusher.connection.bind('connecting', () => {
        window.sockets.connected = false;
        window.sockets.status = 'connecting';
    });
    window.Echo.connector.pusher.connection.bind('connected', () => {
        window.sockets.connected = true;
        window.sockets.status = 'connected';

        if (reconnectInterval) {
            clearInterval(reconnectInterval);
        }
    });
    window.Echo.connector.pusher.connection.bind('unavailable', () => {
        window.sockets.connected = false;
        window.sockets.status = 'unavailable';
    });
    window.Echo.connector.pusher.connection.bind('failed', () => {
        window.sockets.connected = false;
        window.sockets.status = 'failed';
        reconnect();
    });
    window.Echo.connector.pusher.connection.bind('disconnected', () => {
        window.sockets.connected = false;
        window.sockets.status = 'disconnected';
        reconnect();
    });
    window.Echo.connector.pusher.connection.bind('state_change', function (states) {
        // states = {previous: 'oldState', current: 'newState'}
        // console.log(`State changed from ${states.current} to ${states.previous}`);
    });
    window.Echo.connector.pusher.connection.bind('error', (error) => {
        if (error.data && error.data.code) {
            console.log(`error - ${error.type} (${error.data.code}) - ${error.data.message}`);
            window.sockets.error = `${error.type} (${error.data.code}) - ${error.data.message}`;
        }
    });
}